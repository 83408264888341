import type { FC, ReactNode } from 'react';
import React, { useEffect } from 'react';
import {
  Book as SpecificationsIcon,
  BookOpen as BookOpenIcon,
  Clock as ClockIcon,
  Edit as NotesIcon,
  FileText as FileIcon,
  Grid as GridIcon,
  Home as HomeIcon,
  Icon,
  Image as ImageIcon,
  Save as ExportIcon,
  Users as UsersIcon,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { RecentActorsOutlined as RecentActorsIcon } from '@mui/icons-material';
import { Box, Divider, Drawer, List, ListSubheader, Typography } from '@mui/material';
import { TFunction } from 'i18next';

import Image from 'src/components/Image/Image';
import Logo from 'src/components/Logo/Logo';
import routes from 'src/routes';
import { useUserStore } from 'src/services/auth/auth';
import { Permission, usePermissions } from 'src/services/auth/permissions';
import TestIDs from 'src/TestIDs.json';
import { Role, UserLegalEntity } from 'src/types';

import NavItem from './NavItem/NavItem';
import useStyles from './NavBar.styles';

const CustomersIcon = () => <RecentActorsIcon style={{ marginRight: 4 }} />;

const getSectionsForRole = (
  legalEntity: UserLegalEntity,
  hasPermission: (permission: Permission) => boolean,
  hasPermissions: (permissions: Permission[]) => boolean,
  t: TFunction, // Allows reacting to language changes
): Section[] => {
  if (legalEntity.role === Role.CONTRACTOR) {
    return [
      {
        subheader: t('NavBar.sections.management.subheader'),
        items: [
          {
            href: routes.dashboard.getLinkPath(),
            icon: HomeIcon,
            title: t('NavBar.sections.management.items.dashboard.title'),
            dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.dashboard,
          },
          {
            href: routes.contractorProjects.getLinkPath(),
            icon: GridIcon,
            title: t('NavBar.sections.management.items.contractorProjects.title'),
            items: [
              {
                href: routes.contractorProjects.getLinkPath(),
                title: t('NavBar.sections.management.items.contractorProjects.items.projectList.title'),
              },
              hasPermission(Permission.CONTRACTORS_ADD_CONTRACTOR_PROJECT) && {
                href: routes.contractorProjects.routes.create.getLinkPath(),
                title: t('NavBar.sections.management.items.contractorProjects.items.projectCreate.title'),
              },
            ],
          },
          {
            href: routes.photos.getLinkPath(),
            icon: ImageIcon,
            title: t('NavBar.sections.management.items.photos.title'),
            items: [
              {
                href: routes.photos.getLinkPath(),
                title: t('NavBar.sections.management.items.photos.items.photoList.title'),
              },
              {
                href: routes.photos.routes.create.getLinkPath(),
                title: t('NavBar.sections.management.items.photos.items.photoCreate.title'),
              },
            ],
          },
          hasPermission(Permission.NOTES_VIEW_PROJECTNOTE) && {
            href: routes.notes.getLinkPath(),
            icon: NotesIcon,
            title: t('NavBar.sections.management.items.notes.title'),
            dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.projectNotes,
            items: [
              {
                href: routes.notes.getLinkPath(),
                title: t('NavBar.sections.management.items.notes.items.notesList.title'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.projectNotesList,
              },
              hasPermission(Permission.NOTES_ADD_PROJECTNOTE) && {
                href: routes.notes.routes.create.getLinkPath(),
                title: t('NavBar.sections.management.items.notes.items.notesCreate.title'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.createProjectNote,
              },
            ],
          },
          {
            href: routes.documents.getLinkPath(),
            icon: FileIcon,
            title: t('NavBar.sections.management.items.documents.title'),
            dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.documents,
            items: [
              {
                href: routes.documents.getLinkPath(),
                title: t('NavBar.sections.management.items.documents.items.documentsList.title'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.documentsList,
              },
              {
                href: routes.documents.routes.create.getLinkPath(),
                title: t('NavBar.sections.management.items.documents.items.documentsCreate.title'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.documentsCreate,
              },
            ],
          },
          {
            href: routes.specifications.getLinkPath(),
            icon: SpecificationsIcon,
            title: t('NavBar.sections.management.items.specifications.title'),
            items: [
              {
                href: routes.specifications.getLinkPath(),
                title: t('NavBar.sections.management.items.specifications.items.specificationList.title'),
              },
              hasPermission(Permission.SPECIFICATIONS_IMPORT_GAEB_FILES) && {
                href: routes.specifications.routes.create.getLinkPath(),
                title: t('NavBar.sections.management.items.specifications.items.specificationCreate.title'),
              },
            ],
          },
          {
            href: routes.employees.getLinkPath(),
            icon: UsersIcon,
            title: t('NavBar.sections.management.items.employees.title'),
            dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.employees,
            items: [
              {
                href: routes.employees.getLinkPath(),
                title: t('NavBar.sections.management.items.employees.items.employeeList.title'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.employeesList,
              },
              hasPermissions([
                Permission.USERS_ADD_USER,
                Permission.USERS_CHANGE_CONTRACTOR_USER_CONFIGURATION,
                Permission.LEGAL_ENTITIES_VIEW_CONTRACTOR_CONFIGURATION,
              ]) && {
                href: routes.employees.routes.create.getLinkPath(),
                title: t('NavBar.sections.management.items.employees.items.createEmployee.title'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.createEmployee,
              },
            ],
          },
          (hasPermission(Permission.CUSTOMERS_VIEW_CUSTOMER) || hasPermission(Permission.CUSTOMERS_ADD_CUSTOMER)) && {
            href: routes.customers.getLinkPath(),
            icon: CustomersIcon,
            title: t('NavBar.sections.management.items.customers.title'),
            dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.customers,
            items: [
              hasPermission(Permission.CUSTOMERS_VIEW_CUSTOMER) && {
                href: routes.customers.getLinkPath(),
                title: t('NavBar.sections.management.items.customers.items.customerList'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.customerList,
              },
              hasPermission(Permission.CUSTOMERS_ADD_CUSTOMER) && {
                href: routes.customers.routes.create.getLinkPath(),
                title: t('NavBar.sections.management.items.customers.items.create'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.customerCreate,
              },
            ],
          },
          (hasPermission(Permission.TIME_TRACKING_VIEW_TIMEBOOKING) ||
            hasPermissions([
              Permission.TIME_TRACKING_ADD_TEAMTIMEBOOKING,
              Permission.TIME_TRACKING_ADD_TIMEBOOKING,
            ])) && {
            href: routes.timeBookings.getLinkPath(),
            icon: ClockIcon,
            title: t('General.staffTimes'),
            items: [
              hasPermission(Permission.TIME_TRACKING_VIEW_TIMEBOOKING) && {
                href: routes.timeBookings.getLinkPath(),
                title: t('NavBar.sections.management.items.timeBooking.items.timeBookingList.title'),
              },
              hasPermissions([
                Permission.TIME_TRACKING_ADD_TEAMTIMEBOOKING,
                Permission.TIME_TRACKING_ADD_TIMEBOOKING,
              ]) && {
                href: routes.timeBookings.routes.create.getLinkPath(),
                title: t('NavBar.sections.management.items.timeBooking.items.timeBookingCreate.title'),
              },
            ],
          },
          (hasPermission(Permission.REPORTS_VIEW_REPORT) || hasPermission(Permission.REPORTS_ADD_REPORT)) && {
            href: routes.reports.getLinkPath(),
            icon: BookOpenIcon,
            title: t('NavBar.sections.management.items.reports.title'),
            dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.reports,
            items: [
              hasPermission(Permission.REPORTS_VIEW_REPORT) && {
                href: routes.reports.getLinkPath(),
                title: t('NavBar.sections.management.items.reports.items.list'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.reportsList,
              },
              hasPermission(Permission.REPORTS_ADD_REPORT) && {
                href: routes.reports.routes.create.getLinkPath(),
                title: t('NavBar.sections.management.items.reports.items.create'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.reportsCreate,
              },
            ],
          },
          hasPermission(Permission.TIME_TRACKING_EXPORT_TIMEBOOKINGS) && {
            href: routes.exports.getLinkPath(),
            icon: ExportIcon,
            title: t('NavBar.sections.management.items.exports.title'),
            dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.exports,
            items: [
              hasPermission(Permission.TIME_TRACKING_EXPORT_TIMEBOOKINGS) && {
                href: routes.exports.routes.timeBookings.getLinkPath(),
                title: t('General.staffTimes'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.exportTimeBookings,
              },
            ],
          },
        ],
      },
    ];
  } else if (legalEntity.role === Role.GENERAL_CONTRACTOR) {
    return [
      {
        subheader: t('NavBar.sections.management.subheader'),
        items: [
          {
            href: routes.constructionProjects.getLinkPath(),
            icon: GridIcon,
            title: t('NavBar.sections.management.items.constructionProjects.title'),
            dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.constructionProjects,
            items: [
              {
                href: routes.constructionProjects.getLinkPath(),
                title: t('NavBar.sections.management.items.constructionProjects.items.projectList.title'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.constructionProjectsList,
              },
            ],
          },
          {
            href: routes.photos.getLinkPath(),
            icon: ImageIcon,
            title: t('NavBar.sections.management.items.photos.title'),
            items: [
              {
                href: routes.photos.getLinkPath(),
                title: t('NavBar.sections.management.items.photos.items.photoList.title'),
              },
              {
                href: routes.photos.routes.create.getLinkPath(),
                title: t('NavBar.sections.management.items.photos.items.photoCreate.title'),
              },
            ],
          },
          {
            href: routes.documents.getLinkPath(),
            icon: FileIcon,
            title: t('NavBar.sections.management.items.documents.title'),
            dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.documents,
            items: [
              {
                href: routes.documents.getLinkPath(),
                title: t('NavBar.sections.management.items.documents.items.documentsList.title'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.documentsList,
              },
              {
                href: routes.documents.routes.create.getLinkPath(),
                title: t('NavBar.sections.management.items.documents.items.documentsCreate.title'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.documentsCreate,
              },
            ],
          },
          {
            href: routes.employees.getLinkPath(),
            icon: UsersIcon,
            title: t('NavBar.sections.management.items.employees.title'),
            dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.employees,
            items: [
              {
                href: routes.employees.getLinkPath(),
                title: t('NavBar.sections.management.items.employees.items.employeeList.title'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.employeesList,
              },
              hasPermission(Permission.USERS_ADD_USER) && {
                href: routes.employees.routes.create.getLinkPath(),
                title: t('NavBar.sections.management.items.employees.items.createEmployee.title'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.createEmployee,
              },
            ],
          },
          (hasPermission(Permission.CUSTOMERS_VIEW_CUSTOMER) || hasPermission(Permission.CUSTOMERS_ADD_CUSTOMER)) && {
            href: routes.customers.getLinkPath(),
            icon: CustomersIcon,
            title: t('NavBar.sections.management.items.customers.title'),
            dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.customers,
            items: [
              hasPermission(Permission.CUSTOMERS_VIEW_CUSTOMER) && {
                href: routes.customers.getLinkPath(),
                title: t('NavBar.sections.management.items.customers.items.customerList'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.customerList,
              },
              hasPermission(Permission.CUSTOMERS_ADD_CUSTOMER) && {
                href: routes.customers.routes.create.getLinkPath(),
                title: t('NavBar.sections.management.items.customers.items.create'),
                dataTestId: TestIDs.layouts.dashboardLayout.navBar.items.customerCreate,
              },
            ],
          },
        ],
      },
    ];
  } else if (legalEntity.role === Role.PLATFORM_MAINTAINER) {
    return [];
  }
};

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: Icon;
  info?: ReactNode;
  items?: Item[];
  title: string;
  dataTestId?: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const { user } = useUserStore();
  const { hasPermission, hasPermissions } = usePermissions();

  const sections = getSectionsForRole(user.legalEntity, hasPermission, hasPermissions, t);

  const reduceChildRoutes = ({
    acc,
    depth,
    item,
    pathname,
  }: {
    acc: any[];
    depth: number;
    item: Item;
    pathname: string;
  }): any[] => {
    if (!item || !item.href) return acc;
    const key = item.title + depth;

    if (item.items) {
      const open = matchPath(
        {
          path: item.href,
          end: false,
        },
        pathname,
      );

      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={!!open}
          title={item.title}
          data-test-id={item.dataTestId}
        >
          {renderNavItems({
            depth: depth + 1,
            items: item.items,
            pathname,
          })}
        </NavItem>,
      );
    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
          data-test-id={item.dataTestId}
        />,
      );
    }

    return acc;
  };

  const renderNavItems = ({
    depth = 0,
    items,
    pathname,
  }: {
    depth?: number;
    items: Item[];
    pathname: string;
  }): React.ReactNode => {
    return (
      <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>
    );
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ display: { lg: 'none', xs: 'block' } }}>
          <Box className={classes.mobileDrawerHeader} display="flex" justifyContent="center" p={2}>
            <RouterLink to={routes.root.getLinkPath()}>
              <Logo />
            </RouterLink>
          </Box>
        </Box>

        <Box p={2}>
          {user.legalEntity.logo && (
            <Box display="flex" justifyContent="center">
              <Image
                className={classes.legalEntityLogo}
                src={user.legalEntity.logo}
                data-test-id={TestIDs.layouts.dashboardLayout.navBar.items.legalEntityLogo}
                alt="logo"
              />
            </Box>
          )}

          <Box mt={2} textAlign="center">
            <Typography
              variant="h5"
              component="span"
              data-test-id={TestIDs.layouts.dashboardLayout.navBar.items.legalEntityName}
            >
              {user.legalEntity.name}
            </Typography>
          </Box>
        </Box>

        <Divider className={classes.divider} />

        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky className={classes.subHeader}>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Box sx={{ display: { lg: 'none', xs: 'block' } }}>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          data-test-id={TestIDs.layouts.dashboardLayout.navBar.wrapper}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Box>

      <Box sx={{ display: { lg: 'block', xs: 'none' } }}>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          data-test-id={TestIDs.layouts.dashboardLayout.navBar.wrapper}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Box>
    </>
  );
};

export default NavBar;
